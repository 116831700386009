<template>
      <div class="limiter">
        <div class="container-login100">
          <div class="wrap-login100 p-t-50 p-b-90">
            <form class="login100-form validate-form flex-sb flex-w" @submit.prevent="userLogin">
              <span class="login100-form-title p-b-51">
                Войти
              </span>

              
              <div class="wrap-input100 validate-input m-b-16" data-validate = "Username is required">
                <input class="input100" type="email" name="username" placeholder="e-mail" v-model="user.email" />
                <span class="focus-input100"></span>
              </div>
              
              
              <div class="wrap-input100 validate-input m-b-16" data-validate = "Password is required">
                <input class="input100" type="password" name="pass" placeholder="Password" v-model="user.password" />
                <span class="focus-input100"></span>
              </div>
              
             

              <div class="container-login100-form-btn m-t-17">
                <button @click="userLogin" class="login100-form-btn">
                  Войти
                </button>

              </div>

               <div class="flex-sb-m w-full p-t-24 p-b-24 ">
                <div class="contact100-form-checkbox">
                    <router-link class="txt1" to="/forgot-password">Забыли пароль?</router-link>
                </div>

                <div>
                    <router-link class="txt1" to="/">Регистрация</router-link>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
</template>


<script>
import firebase from "firebase";

export default {
  data() {
    return {
      user: {   
        email: '',
        password: ''
      }
    };
  },
   created() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.user = user;
        this.$router.push('/home');
      } 
    });
  },
  methods: {
    userLogin() {
        firebase
        .auth()
        .signInWithEmailAndPassword(this.user.email, this.user.password)
        .then(() => {
            this.$router.push('/home')
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  }
};
</script>



<style scoped >
@import '../assets/login/vendor/bootstrap/css/bootstrap.min.css';
@import '../assets/login/fonts/font-awesome-4.7.0/css/font-awesome.min.css';
@import '../assets/login/fonts/Linearicons-Free-v1.0.0/icon-font.min.css';
@import '../assets/login/vendor/animate/animate.css';
@import '../assets/login/vendor/css-hamburgers/hamburgers.min.css';
@import '../assets/login/vendor/animsition/css/animsition.min.css';
@import '../assets/login/vendor/select2/select2.min.css';
@import '../assets/login/vendor/daterangepicker/daterangepicker.css';
@import '../assets/login/css/util.css';
@import '../assets/login/css/main.css';
</style>